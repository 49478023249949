import { ColorModeScript,ChakraBaseProvider,theme } from '@chakra-ui/react';
import ColorModeSwitcher from './ColorModeSwitcher'
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <ColorModeScript />
    <ChakraBaseProvider theme={theme}>
      <ColorModeSwitcher/>
      <App/>
    </ChakraBaseProvider>
  </StrictMode>
);


